<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      background-color="white"
      text-color="#4B4B4B"
      active-text-color="#20a0ff"
      unique-opened
      router
      @select="handlerSelect"
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.title">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.title">
                <template slot="title">{{ subItem.title }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">{{ threeItem.title }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.index" :key="subItem.title">{{ subItem.title }}</el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from '../common/bus';
export default {
  data() {
    return {
      user_group: localStorage.getItem('user-group'),
      items: [],
      /* collapse: true, */
      temp: [
        {
          icon: 'el-icon-s-home',
          index: 'dashboard',
          title: '系统首页'
        },
        // item 1 产品List
        {
          icon: 'el-icon-suitcase',
          index: '1',
          title: '产品',
          subs: [
            // {
            //   index: 'product-table',
            //   title: '基础数据模块'
            // },
            // {
            //   index: 'product-table2',
            //   title: '付费数据模块'
            // },
            {
              index: 'product-table3',
              title: '日报模块'
            },
            // {
            //   index: 'product-table4',
            //   title: '付费事件分析'
            // },
            // {
            //   index: 'product-table9',
            //   title: '群组分析'
            // },
            // {
            //   index: 'product-table5',
            //   title: '分批次模块'
            // },
            {
              index: 'product-lofInfo',
              title: '买量产品数据'
            },
            {
              index: 'product-table6',
              title: '买量回本数据-安卓'
            },
            {
              index: 'product-table15',
              title: '买量回本数据-iOS'
            },
            {
              index: 'product-table7',
              title: 'Loongcheer项目模块'
            },
            {
              index: 'product-project-data-starboom',
              title: 'Starboom项目模块'
            },
            // {
            //   index: 'product-table8',
            //   title: '项目模块-AF'
            // },
            {
              index: 'product-table10',
              title: 'Admob数据报表'
            },
            {
              index: 'product-table13',
              title: '项目历史报表'
            },
            {
              index: 'product-table14',
              title: '活跃数据报表'
            },
            {
              index: 'product-table-user',
              title: '用户行为路径'
            },
            {
              index: 'product-table-FunnelAnalysis',
              title: '漏斗分析'
            },
            {
              index: 'product-table-DataDemand',
              title: '数据需求'
            },
            {
              index: 'product-table-UserCustom',
              title: '用户定制'
            },
            {
              index:'product-table-reviews',
              title: '谷歌评论'
            },
            {
              index:'OfflineOrder',
              title: '线下订单录入'
            }
          ]
        },
        // 市场组 index 2 
        {
          icon: 'el-icon-shopping-bag-1',
          index: '2',
          title: '市场',
          subs: [
            {
              index: 'market-table1',
              title: '市场消耗信息'
            },
            // {
            //   index: 'market-table2',
            //   title: '分时统计'
            // },
            // {
            //   index: 'market-table3',
            //   title: '个人的消耗统计'
            // },
            {
              index: 'market-ltvInfo',
              title: '产品LTV数据'
            },  
            {
              index: 'market-lofInfo',
              title: '买量产品数据'
            },
            // {
            //   index: 'market-table4',
            //   title: '素材统计'
            // },
            // {
            //   index: 'market-table5',
            //   title: '用户查询'
            // },
            // {
            //   index: 'market-table5a',
            //   title: '项目付费监控'
            // },
            {
              index: 'market-table6',
              title: '买量回本数据-安卓'
            },
            {
              index: 'market-table15',
              title: '买量回本数据-iOS'
            },
            // {
            //   index: 'MarketCampaign',
            //   title: '买量数据'
            // },
            // {
            //   index: 'market-uac2.5',
            //   title: 'UAC2.5事件分析'
            // },
            // {
            //   index: 'market-table_pixelCohort',
            //   title: '广告素材群组分析'
            // },
            // {
            //   index: 'market-pixel',
            //   title: '素材标记'
            // },
            {
              index: 'market-table7',
              title: 'Loongcheer项目模块'
            },
            // {
            //   index: 'market-table8',
            //   title: '项目模块-AF'
            // }
          ]
        },
        // 海外户管理 index 3 
        {
          icon: 'el-icon-office-building',
          index: '3',
          title: '泓利数据',
          subs: [
            {
              index: 'outsea-account',
              title: 'ADMAX户消耗信息'
            },
            {
              index: 'outsea-YFaccount',
              title: '预付账户消耗数据模块'
            },
          ]
        },
        // 财务 index 4 
        {
          icon: 'el-icon-s-order',
          index: '4',
          title: '财务',
          subs: [
            {
              index: 'loongcheer-core',
              title: 'Loongcheer流水数据'
            },
            {
              index: 'loongcheer-report',
              title: 'Loongcheer周报数据'
            }
          ]
        },
        // GM管理 index 5
        {
          icon: 'el-icon-magic-stick',
          // 这个值好像是标记元素在数组的位置
          index: '10',
          title: 'GM数据面板',
          subs: [
            {
              index: 'gm-main',
              title: '总数据'
            },
            {
              index: 'gm-server',
              title: '区服数据'
            },
            {
              index: 'gm-serverpay',
              title: '区服付费排行'
            },
            {
              index: 'beyondwarrior-event2',
              title: '事件数据'
            },
            {
              index: 'gm-item',
              title: '道具流水'
            },
            {
              index: 'gm-mail',
              title: '邮件查询'
            },
            {
              index: 'gm-hero',
              title: '英雄养成'
            },
          ]
        },
        // index 6 对外提供给闲侠CP的
        {
          icon: 'el-icon-magic-stick',
          // 这个值好像是标记元素在数组的位置
          index: '11',
          title: '武林闲侠数据面板',
          subs: [
            {
              index: 'beyondwarrior-main',
              title: '总数据'
            },
            {
              index: 'beyondwarrior-event',
              title: '事件数据'
            }
          ]
        },
        // Stickman GM管理 index 7
        {
          icon: 'el-icon-magic-stick',
          // 这个值好像是标记元素在数组的位置
          index: '12',
          title: 'Stickman3数据面板',
          subs: [
            {
              index: 'stickman3-main',
              title: '总数据'
            },
            {
              index: 'stickman3-server',
              title: '区服数据'
            },
            {
              index: 'stickman3-serverpay',
              title: '区服付费排行'
            },
    
          ]
        },
        {
          icon: 'el-icon-magic-stick',
          // 这个值好像是标记元素在数组的位置
          index: '9',
          title: '变现',
          subs: [
            {
              index: 'AdCash-AllReport',
              title: '各广告平台数据报表'
            },
            {
              index: 'iaa-basic',
              title: '变现基本数据'
            },
          ]
        },
        {
          icon: 'el-icon-menu',
          index: '5',
          title: '聚合管理',
          subs: [
            {
              index: '403',
              title: '应用管理'
            },
            {
              index: '403',
              title: '广告位管理'
            },
            {
              index: '403',
              title: '流量分组管理'
            },
            {
              index: '403',
              title: '广告平台'
            }
          ]
        },
        {
          icon: 'el-icon-s-data',
          index: '6',
          title: '数据报表',
          subs: [
            {
              index: '403',
              title: '用户留存'
            },
            {
              index: '403',
              title: '事件数据'
            },
            {
              index: '403',
              title: '收益数据'
            },
            {
              index: '403',
              title: '参透率&用户观看次数'
            },
            {
              index: '403',
              title: '分时报表'
            }
          ]
        },
        {
          icon: 'el-icon-coin',
          index: '7',
          title: '数据库',
          subs: [
            {
              index: 'data-table1',
              title: '脚本'
            },
            {
              index: '403',
              title: '测试专用'
            }
          ]
        },
        {
          icon: 'el-icon-s-tools',
          index: '8',
          title: '权限管理',
          subs: [
            {
              index: 'user',
              title: '用户管理'
            },
            {
              index: 'user-group',
              title: '用户组管理'
            }
          ]
        }
        /* {
                    icon: 'el-icon-lx-copy',
                    index: 'tabs',
                    title: '消息中心'
                }, */
        /* {
                    icon: 'el-icon-lx-copy',
                    index: 'tabs',
                    title: 'tab选项卡'
                },
                {
                    icon: 'el-icon-lx-calendar',
                    index: '3',
                    title: '表单相关',
                    subs: [
                        {
                            index: 'form',
                            title: '基本表单'
                        },
                        {
                            index: '3-2',
                            title: '三级菜单',
                            subs: [
                                {
                                    index: 'editor',
                                    title: '富文本编辑器'
                                },
                                {
                                    index: 'markdown',
                                    title: 'markdown编辑器'
                                }
                            ]
                        },
                        {
                            index: 'upload',
                            title: '文件上传'
                        }
                    ]
                },
                {
                    icon: 'el-icon-lx-emoji',
                    index: 'icon',
                    title: '自定义图标'
                },
                {
                    icon: 'el-icon-pie-chart',
                    index: 'charts',
                    title: 'schart图表'
                },
                {
                    icon: 'el-icon-rank',
                    index: '6',
                    title: '拖拽组件',
                    subs: [
                        {
                            index: 'drag',
                            title: '拖拽列表'
                        },
                        {
                            index: 'dialog',
                            title: '拖拽弹框'
                        }
                    ]
                },
                {
                    icon: 'el-icon-lx-global',
                    index: 'i18n',
                    title: '国际化功能'
                },
                {
                    icon: 'el-icon-lx-warn',
                    index: '7',
                    title: '错误处理',
                    subs: [
                        {
                            index: 'permission',
                            title: '权限测试'
                        },
                        {
                            index: '404',
                            title: '404页面'
                        }
                    ]
                },
                {
                    icon: 'el-icon-lx-redpacket_fill',
                    index: '/donate',
                    title: '支持作者'
                } */
      ]
    };
  },
  methods: {
    handlerSelect(index, indexPath) {
      console.log(index, indexPath);
    },
    shaixuan() {
      if (this.user_group.indexOf('测试组') == -1) {
        if (this.user_group.indexOf('ADMAX户组') > -1 ) {
          this.items = [this.temp[3]];
        }
        if (this.user_group.indexOf('产品组') > -1 && this.user_group.indexOf('市场组') > -1) {
          this.items = [this.temp[0], this.temp[1], this.temp[2], this.temp[5], this.temp[7]];
        }
        if (this.user_group.indexOf('产品组') > -1 && this.user_group.indexOf('市场组') == -1) {
          this.items = [this.temp[0], this.temp[1], this.temp[5], this.temp[7]];
        }
        if (this.user_group.indexOf('市场组') > -1 && this.user_group.indexOf('产品组') == -1) {
          this.items = [this.temp[0], this.temp[2]];
        }
        if (this.user_group.indexOf('闲侠组') > -1) {
          this.items = [this.temp[6]];
        }
        if (this.user_group.indexOf('财务组') > -1) {
          this.items.push(this.temp[4]);
        }
        // console.log(this.temp);
        // this.items = this.temp;
        // this.temp = [];
      }else{
        this.items = this.temp;
      }
    }
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace('/', '');
    }
  },
  created() {
    this.shaixuan();
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    /*  bus.$on('collapse', msg => {
            this.collapse = msg;
            bus.$emit('collapse-content', msg);
        }); */
  }
};
</script>

<style scoped lang="scss">
.sidebar-el-menu{

  ::v-deep .el-menu-item.is-active{
    background-color: #e6f3fe !important;
  }

  ::v-deep .el-submenu.is-active .el-submenu__title{
    color: #20a0ff !important;

    > i{
      color: inherit;
    }
  }
}
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.sidebar > ul {
  height: 100%;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
