<template>
    <div class="header">
        <!-- 折叠按钮 -->
        <!-- <div class="collapse-btn" @click="collapseChage">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div> -->
        <div class="logo" style="margin-left:26px;">
            <img style="width:120px;height:20px;" src="../../assets/img/logo.png" alt="">
            <!-- <font style="margin-left:10px;">Loongcheer</font> -->
        </div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 全屏显示 -->
                <div class="btn-fullscreen" @click="handleFullScreen">
                    <el-tooltip effect="dark" :content="fullscreen?`取消全屏`:`全屏`" placement="bottom">
                        <i class="el-icon-rank"></i>
                    </el-tooltip>
                </div>
                <!-- 消息中心 -->
                <!-- <div class="btn-bell">
                    <el-tooltip
                        effect="dark"
                        :content="message?`有${message}条未读消息`:`消息中心`"
                        placement="bottom"
                    >
                        <router-link to="/tabs">
                            <i class="el-icon-bell"></i>
                        </router-link>
                    </el-tooltip>
                    <span class="btn-bell-badge" v-if="message"></span>
                </div> -->
                <!-- 用户头像 -->
                <div class="user-avator" @click="editVisible=true">
                    <img :src="touxiang" />
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <a @click="show" target="_blank">
                            <el-dropdown-item>2048</el-dropdown-item>
                        </a>
                        <a @click="show_game" target="_blank">
                            <el-dropdown-item>抓小猫</el-dropdown-item>
                        </a>
                        <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <!-- 头像选择弹窗 -->
        <el-dialog title="头像选择" :visible.sync="editVisible" width="40%">
            <div style="width:30%;overflow-y:scroll;height:200px;display:inline-block;">
                <el-button style="display:block;" v-for="item in img" :key="item" @click="genghuan(item)" type="text">{{item}}</el-button>
            </div>
            <div style="display:inline-block;margin-left:20px;">
                <img :src="touxiang2" alt="用户头像" style="width:200px;height:200px;">

            </div>
            <div style="margin-top:40px;">
                上传地址：<el-input v-model="dizhi" placeholder="图片链接" clearable style="width:80%;"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit" :loading="time">{{timename}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import bus from '../common/bus';
export default {
    data() {
        return {
            dizhi:'',
            editVisible:false,
            img:[
                'img1.jpg','img2.jpg','img3.jpg','img4.jpg','img5.jpg','img6.jpg','img7.jpg','img8.jpg','img9.jpg'
                ,'img10.jpg','img11.jpg','img12.jpg','img13.jpg'
            ],
            xuanzetx:'',
            touxiang2:require('../../assets/img/img1.jpg'),
            touxiang:'',
            collapse: true,
            fullscreen: false,
            name: 'chen',
            message: 2,
            time:false,
            timename:'确定'
        };
    },
    computed: {
        username() {
            let username = localStorage.getItem('ms_username');
            return username ? username : this.name;
        }
    },
    methods: {
        //更换头像框
        genghuan(tx){
            this.dizhi=''
            setTimeout(() => {
            this.xuanzetx=tx
            this.touxiang2=require('../../assets/img/'+this.xuanzetx)
                
            }, 100);
        },
        //更换头像框确定
        saveEdit(){
            this.time=true
            this.timename='上传中'
            if(this.dizhi!='')
            {
                this.touxiang=this.dizhi
                localStorage.setItem('touxiangLJ',this.dizhi)
            }
            else{
                this.touxiang=require('../../assets/img/'+this.xuanzetx)
                localStorage.setItem('touxiang',this.xuanzetx)
                localStorage.removeItem('touxiangLJ')
            }
            setTimeout(() => {
                this.time=false
                this.timename='确定'
                this.$message({
                    message: '上传成功',
                    type: 'success'
                    });
                this.editVisible=false
            }, 1000);
        },
        demo(){
            var a =Math.floor(Math.random()*6);
            this.touxiang=require('../../assets/img/img'+a+'.jpg')
        },
        show(){
            this.$router.push('/2048')
        },
        show_game(){
            this.$router.push('/catch-cat')
        },
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command == 'loginout') {
                localStorage.removeItem('ms_username');
                localStorage.removeItem('ms_password');
                this.$router.push('/login');
            }
        },
        // 侧边栏折叠
        collapseChage() {
            this.collapse = !this.collapse;
            bus.$emit('collapse', this.collapse);
        },
        // 全屏事件
        handleFullScreen() {
            let element = document.documentElement;
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
            this.fullscreen = !this.fullscreen;
        }
    },
    mounted() {
        if(localStorage.getItem('touxiangLJ')!=null)
        {
            this.dizhi=localStorage.getItem('touxiangLJ')
            this.touxiang2=localStorage.getItem('touxiangLJ')
            this.touxiang=this.dizhi
        }

        if(localStorage.getItem('touxiangLJ')==null){
            
        if(localStorage.getItem('touxiang')==null)
        {
            this.touxiang='https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1606372357983&di=61e76066c1e51c3d9753113be83eb7c6&imgtype=0&src=http%3A%2F%2Fimg.duoziwang.com%2F2018%2F01%2F2022574830661.jpg'
            this.touxiang2='https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1606372357983&di=61e76066c1e51c3d9753113be83eb7c6&imgtype=0&src=http%3A%2F%2Fimg.duoziwang.com%2F2018%2F01%2F2022574830661.jpg'
        }
        else
            {
                this.xuanzetx=localStorage.getItem('touxiang')
                this.touxiang=require('../../assets/img/'+this.xuanzetx)
                this.touxiang2=require('../../assets/img/'+this.xuanzetx)
            }
        }
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    },
    watch:{
        dizhi(){
            this.touxiang2=this.dizhi
        }
    }
};
</script>
<style scoped>
.el-button--text{
    color:gray;
}
.el-button:first-child{
    margin-left:10px;
}
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    font-size: 22px;
    color: #fff;
    background: #424242;
}
.collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 60px;
}
.header .logo {
    display:flex;
    align-items: center;
    float: left;
    width: 250px;
    height: 60px;
}
.header-right {
    float: right;
    padding-right: 50px;
}
.header-user-con {
    display: flex;
    height: 60px;
    align-items: center;
}
.btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.user-name {
    margin-left: 10px;
}
.user-avator {
    margin-left: 20px;
}
.user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.el-dropdown-link {
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
</style>
